import React, {useEffect} from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import EncuestaForm from "./templates/encuesta-formulario"
 
const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const EncuestaFormulario = ({ location }) => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        const response = await fetch(`${URL}/login/validarLogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        });

        const responseData = await response.json();

        if(!responseData.ok){
          navigate('/encuesta-de-satisfaccion/login')
        }

      } catch (error) {
        navigate('/encuesta-de-satisfaccion/login')
      }
    };
    fetchData();
}, []);

  return (  
    <Layout location={location}>
      <SEO title="Encuesta de satisfaccion" />
      <EncuestaForm />
    </Layout>
  )
}
 
export default EncuestaFormulario